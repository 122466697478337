import { TablePaginationTP } from 'common/old-refactory/table/TableCPOLD'
import { StyledComponentProps } from 'main/config/theme/Theme'
import _ from 'lodash'
import { CardCP } from 'main/common/components/card/CardCP'
import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ComponentUtils } from 'main/common/utils/ComponentUtils'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { PersonTableContext } from 'main/modules/people/components/person-table/inner/PersonTableContext'
import { PersonTableUtils } from 'main/modules/people/components/person-table/inner/PersonTableUtils'
import { IProfessionalResponseDTO } from 'main/modules/people/services/person/dtos/response/IProfessionalResponseDTO'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import { ISearchUserByExpressionRequestDTO } from 'main/modules/user/services/dtos/request/ISearchUserByExpressionRequestDTO'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

/**
 * COMPONENTE
 * Parte interna da tabela do CRM para colaboradores.
 */
export const TableInnerCollaboratorICP = ComponentUtils.memoizeCp((): JSX.Element => {

    const tableContext = PersonTableContext.use()

    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const [personList, setPersonList] = useState<IProfessionalResponseDTO[]>([])

    const searchPeopleRequest = useRequest<IGenericListResponseDTO<IProfessionalResponseDTO>>('api-return', '[collaborator-search]')

    const runSearchDebounced = _.debounce(runPeopleSearch, 150)

    useEffect(() => runSearchDebounced(false), [pagination.current])
    useEffect(() => runSearchDebounced(true), [tableContext.state.segmentExpression])
    useEffect(onShouldUpdateListChange, [tableContext.state.mustUpdate])
    useEffect(onSearchRequestChange, [searchPeopleRequest.isAwaiting]) 

    function runPeopleSearch(resetPagination: boolean): void {

        let nextPagination: TablePaginationTP

        if (resetPagination) {
            nextPagination = TableUtils.getDefaultPagination()
            setPagination(nextPagination)

        } else
            nextPagination = pagination

        const dto: ISearchUserByExpressionRequestDTO = {
            expression: tableContext.state.segmentExpression,
            itemsPerPage: nextPagination.pageSize,
            page: nextPagination.current,
        }

        searchPeopleRequest.runRequest(UserRequests.searchUserByExpressionConfig(dto))
    }

    function onSearchRequestChange(): void {

        tableContext.actions.setIsLoading(searchPeopleRequest.isAwaiting)
        if (!RequestUtils.isValidRequestReturn(searchPeopleRequest, `Erro ao tentar listar ${SegmentUtils.getSegmentTypeName(SegmentTypeEnum.COLLABORATOR)}`))
            return

        // Atualiza listagem / estado
        const isSuccess = RequestUtils.isRequestSuccess(searchPeopleRequest, false)
        updatePeopleList(isSuccess)
    }

    function onShouldUpdateListChange(): void {
        if (tableContext.state.mustUpdate)
            runSearchDebounced(true)
    }

    function updatePeopleList(isSuccess: boolean): void {
        const nextPersonList = SystemUtils.nvl<IProfessionalResponseDTO[]>(isSuccess, searchPeopleRequest.responseData?.list, [])!
        const totalPeople = SystemUtils.nvl<number>(isSuccess, searchPeopleRequest.responseData?.total, 0)!
        setPagination({ ...pagination, total: totalPeople })
        setPersonList(nextPersonList)
    }

    function viewDetails(personCode: number): void {
        tableContext.actions.setShowPersonDetailsDrawer(true)
        tableContext.actions.setSelectedPersonCode(personCode)
    }

    async function desativarAtivar(person:any){
        console.log("A pessoa ", person);
        const resposta:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/users/ativar`,
            method: HttpMethodEnum.POST,
            params: {
                active: !person.active,
                idPessoa:person.code,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
            
        });
        console.log(resposta);
        if(resposta?.data?.data=='ok'){
            NotificationHelper.success('Sucesso',`Situação do usuário alterada para ${person.active?'Inativo':'Ativo'}`);
            runPeopleSearch(true);
        }
    }

    return (
        <CardCP innerSpacing={'none'}>
            <TableWrapperSCP data-tour={'person-table'}>
                <TableCPOLD2
                    data={personList}
                    pagination={pagination}
                    loading={tableContext.state.isLoading}
                    rowKey={(person): string => `${person.code}`}
                    columns={PersonTableUtils.getUserTableColumns(viewDetails,desativarAtivar)}
                    onPaginationChange={(page): void => setPagination({ ...pagination, current: page.current ?? 1 })}
                />
            </TableWrapperSCP>
        </CardCP>
    )
})

const TableWrapperSCP = styled.div`

    .ant-table {
        font-size: 12px;
    }

    .ant-typography {
        margin: 0;
        font-size: ${(props: StyledComponentProps): string => props.theme.fontSizes.small};
    }
`
