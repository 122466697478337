import { TableCPOLD2, TablePaginationTP } from 'common/old-refactory/table/TableCPOLD2'
import { IFinancialTransactionSearchRequestDTO } from 'main/modules/financial/services/financial-transactions/request/IFinancialTransactionSearchRequestDTO'
import { FinancialTransactionsRequests } from 'main/modules/financial/services/financial-transactions/FinancialTransactionsRequests'
import React, { useEffect, useState } from 'react'
import { IFinancialTransactionCreditListItemResponseDTO } from 'main/modules/financial/services/financial-transactions/response/IFinancialTransactionCreditListItemResponseDTO'
import { DrawerSaleDetailsCP } from 'main/modules/sale/components/drawer-sale-details/DrawerSaleDetailsCP'
import { TableUtils } from 'main/common/components/table/inner/TableUtils'
import { FinancialTransactionsCreditTableUtils } from 'main/modules/financial/components/table-financial-transactions-credit/inner/FinancialTransactionsCreditTableUtils'
import { DrawerPersonDetailsCP } from 'main/modules/people/components/drawer-person-details/DrawerPersonDetailsCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { ModalPayMultipleFinancialTransactionCP } from 'main/modules/financial/components/modal-pay-multiple-financial-transaction/ModalPayMultipleFinancialTransactionCP'
import * as _ from 'lodash'
import { CardCP } from 'main/common/components/card/CardCP'
import { ButtonExportCP } from 'main/modules/app/components/button-export/ButtonExportCP'
import { FinancialTransactionRequests } from 'submodules/neritclin-sdk/services/financial/financial/FinancialTransactionRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { FinancialTransactionStatusEnum } from '../../enums/FinancialTransactionStatusEnum'
import moment from 'moment'

interface IFinancialTransactionTableCPProps {
    filters: IFinancialTransactionSearchRequestDTO
    setFilters: (filters: IFinancialTransactionSearchRequestDTO) => void
    onSelectTransaction?: (code: number) => void
    shouldReloadList?: boolean
    onReportLoaded: () => void
    onUpdateAnyData: () => void
    showReferenceDate?: boolean
    isChargingScreen?: boolean
}

/**
 * Componente de tabela que lista as receitas.
 */
export function TableFinancialTransactionsCreditCP(props: IFinancialTransactionTableCPProps): JSX.Element {
    let esconderRegistros:any;
    const [isMultiplePayModalVisible, setIsMultiplePayModalVisible] = useState<boolean>(false)

    const [isSaleDrawerVisible, setIsSaleDrawerVisible] = useState<boolean>(false)
    const [selectedSaleCode, setSelectedSaleCode] = useState<number>()

    const [isPersonDrawerVisible, setIsPersonDrawerVisible] = useState<boolean>(false)
    const [selectedPersonCode, setSelectedPersonCode] = useState<number>()

    const [financialTransactions, setFinancialTransactions] = useState<IFinancialTransactionCreditListItemResponseDTO[]>([])
    const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination())
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    const searchCreditTransactionsRequest = useRequest<ListResponseDTO<IFinancialTransactionCreditListItemResponseDTO>>()
    useEffect(onSearchCreditTransactionsRequestChange, [searchCreditTransactionsRequest.isAwaiting])

    useEffect(init, [props.shouldReloadList])
    useEffect(() => init(true), [pagination.current])

    esconderRegistros = !!AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.VER_SOMENTE_RECEITAS_ATRASADAS);
    

    /**
     * Inicializa
     */
    function init(forceLoad?: boolean): void {

        if (!props.shouldReloadList && !forceLoad)
            return

        if(esconderRegistros){
            props.filters.status = [FinancialTransactionStatusEnum.UNPAID];
        }
        console.log("Os filtros ", props)
        const searchDto: IFinancialTransactionSearchRequestDTO = {
            ...props.filters,
            itemsPerPage: TableUtils.getDefaultPagination().pageSize,
            page: pagination.current
        }

        if (props.isChargingScreen)
            searchCreditTransactionsRequest.runRequest(FinancialTransactionRequests.getBillingDashboardCredits(searchDto))
        else
            searchCreditTransactionsRequest.runRequest(FinancialTransactionsRequests.searchCreditTransactionsConfig(searchDto))
    }

    /**
     * Retorno da busca por receitas.
     */
    function onSearchCreditTransactionsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchCreditTransactionsRequest, 'Erro ao buscar faturas!'))
            return

        let lista = searchCreditTransactionsRequest.responseData!.list;
        let total = searchCreditTransactionsRequest.responseData!.total;
        if(esconderRegistros){
            lista = lista.filter((i:IFinancialTransactionCreditListItemResponseDTO)=>{
                console.log(`STATUS ${i.personPayer.name}`, i.status);
                if(i.status == FinancialTransactionStatusEnum.OVERDUE)return true;
                return moment(i.expirationDate).isBefore(moment().startOf('date')) && (i.status == 'UNPAID');
            });
            total = lista.length;
        }
        setFinancialTransactions(lista)
        setPagination({ ...pagination, total: total })
        props.onReportLoaded()
    }

    /**
     * Evento ao selecionar detalhes de uma venda ou de uma receita eventual
     */
    function onSelectFinancialTransactionOrSale(financialTransaction: IFinancialTransactionCreditListItemResponseDTO): void {

        if (!!financialTransaction.saleCode) {
            setSelectedSaleCode(financialTransaction.saleCode)
            setIsSaleDrawerVisible(true)
            return
        }

        props.onSelectTransaction?.(financialTransaction.code)
    }

    return (
        <>
            <FlexCP marginTop={20} justifyContent={'space-between'}>
                <div>
                    <ConditionalRenderCP shouldRender={!SystemUtils.isEmpty(selectedRowKeys)}>
                        <ButtonCP
                            size={'small'}
                            icon={'audit'}
                            onClick={() => setIsMultiplePayModalVisible(true)}
                        >
                            Marcar como pago
                        </ButtonCP>
                    </ConditionalRenderCP>
                </div>
                <ButtonExportCP
                    requestConfig={() => FinancialTransactionsRequests.exportCredit(TableUtils.removePaginationAttributes(props.filters))}
                    reportName={'receitas'}
                />
            </FlexCP>

            <CardCP innerSpacing={'none'} margin={'vertical'}>
                <TableCPOLD2<IFinancialTransactionCreditListItemResponseDTO>
                    data={financialTransactions}
                    loading={searchCreditTransactionsRequest.isAwaiting}
                    columns={
                        FinancialTransactionsCreditTableUtils.getCreditTableColumns(
                            onSelectFinancialTransactionOrSale,
                            (_personCode) => {
                                setSelectedPersonCode(_personCode)
                                setIsPersonDrawerVisible(true)
                            },
                            props.onUpdateAnyData,
                            props.showReferenceDate
                    )
                    }
                    rowKey={(record) => record.code.toString()}
                    pagination={pagination}
                    onPaginationChange={(page): void => {
                        setPagination({ ...pagination, current: page.current! })
                        props.setFilters({ ...props.filters, page: page.current })
                    }}
                    rowSelection={{
                        onChange: (selectedKeys) => setSelectedRowKeys(_.map(selectedKeys, eachKey => eachKey.toString())),
                        columnWidth: 40,
                    }}
                />
            </CardCP>

            <ModalPayMultipleFinancialTransactionCP
                visible={isMultiplePayModalVisible}
                financialTransactionCodes={_.map(selectedRowKeys, eachKey => +eachKey)}
                onClose={() => setIsMultiplePayModalVisible(false)}
                onSave={() => {
                    setIsMultiplePayModalVisible(false)
                    props.onUpdateAnyData()
                }}
            />

            {
                selectedSaleCode &&
                <DrawerSaleDetailsCP
                    visible={isSaleDrawerVisible}
                    saleCode={selectedSaleCode}
                    onClose={() => setIsSaleDrawerVisible(false)}
                />
            }
            {
                selectedPersonCode &&
                <DrawerPersonDetailsCP
                    visible={isPersonDrawerVisible}
                    personCode={selectedPersonCode}
                    segmentType={SegmentTypeEnum.CUSTOMER}
                    onSave={() => setIsPersonDrawerVisible(false)}
                    onClose={() => setIsPersonDrawerVisible(false)}
                />
            }
        </>
    )
}
