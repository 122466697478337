import React from 'react'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { UserPermissionsDataICP } from 'main/modules/user/components/user-permission/inner/UserPermissionsDataICP'
import { UserSubPermissionsDataICP } from 'main/modules/user/components/user-permission/inner/UserSubPermissionsDataICP'
import styled from 'styled-components'

interface ICPProps {
    permissions: Map<PermissionEnum, boolean>
    onChangePermission: (checked: boolean, permission: PermissionEnum) => void
}

/**
 */
export function UserFinancialPermissionsICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <UserPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.ROLE_FINANCIAL)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.ROLE_FINANCIAL)}
                title={'Financeiro'}
                description={'Acesso ao módulo financeiro.'}
                margin={{}}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.DESPESAS)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.DESPESAS)}
                text={'Acessar Despesas'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.ROLE_CHANGE_PAYMENT_METHOD)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.ROLE_CHANGE_PAYMENT_METHOD)}
                text={'Alterar forma de pagamento de uma venda'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.ROLE_RETRY_CHARGE)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.ROLE_RETRY_CHARGE)}
                text={'Retentativa de cobrança'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.ROLE_SALE_CANCELATION)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.ROLE_SALE_CANCELATION)}
                text={'Cancelar uma venda'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.ROLE_SALE_REFUND)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.ROLE_SALE_REFUND)}
                text={'Estornar uma venda'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.VER_VENDAS_OUTRAS_UNIDADES)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.VER_VENDAS_OUTRAS_UNIDADES)}
                text={'Ver vendas de outras unidades'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!props.permissions.get(PermissionEnum.VER_SOMENTE_RECEITAS_ATRASADAS)}
                onChangeChecked={(checked) => props.onChangePermission(checked, PermissionEnum.VER_SOMENTE_RECEITAS_ATRASADAS)}
                text={'Ver somente receitas atrasadas'}
                cor={'sim'}
            />
            
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  margin-bottom: 20px;
`
