import React, { useEffect, useState } from 'react'
import { BankAccountFormModel } from 'main/modules/financial/components/drawer-bank-account/inner/BankAccountFormModel'
import { IBankAccountResponseDTO } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountResponseDTO'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { BankAccountTypeEnum } from 'main/modules/financial/enums/BankAccountTypeEnum'
import { BankAccountRequests } from 'main/modules/financial/services/bank-account/BankAccountRequests'
import { BankAccountUtils } from 'main/modules/financial/utils/BankAccountUtils'
import { BankAccountModalitySelectorCP } from 'main/modules/financial/components/bank-account-modality-selector/BankAccountModalitySelectorCP'
import { IBankAccountSaveRequestDTO } from 'main/modules/financial/services/bank-account/dtos/request/IBankAccountSaveRequestDTO'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IBankAccountConfig } from 'main/modules/financial/services/bank-account/dtos/reponse/IBankAccountConfig'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { SelectBankByTypeCP } from 'main/modules/financial/components/select-bank-by-type/SelectBankByTypeCP'
import { IconBankAccountLogoCP } from 'main/modules/financial/components/icon-bank-account-logo/IconBankAccountLogoCP'
import { AutocompleteBankAccountCP } from 'main/modules/financial/components/select-bank-account/AutocompleteBankAccountCP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { EditorJsonCP } from 'submodules/nerit-framework-ui/common/components/editor-json/EditorJsonCP'
import { CollapseCP } from 'main/common/components/collapse/CollapseCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { UtilGeral } from 'main/modules/people/utils/UtilGeral'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import styled from 'styled-components'

interface IDrawerBankAccountCPProps {
    visible: boolean
    onClose: (dataChanged: boolean) => void
    bankAccountCode?: number
    type: BankAccountTypeEnum
}

/**
 * Drawer de dados de uma conta bancaria
 */
export function DrawerBankAccountCP(props: IDrawerBankAccountCPProps): JSX.Element {

    useEffect(initialize, [props.visible])
    const [avancado,setAvancado] = useState<boolean>(true);

    const [formValidator, setFormValidator] = useState<BankAccountFormModel>((new BankAccountFormModel()))
    const formStateManager = useFormStateManager<BankAccountFormModel>(formValidator)

    const [loadedBankAccount, setLoadedBankAccount] = useState<IBankAccountResponseDTO>()
    const getBankAccountRequest = useRequest<IBankAccountResponseDTO>()
    useEffect(onGetBankAccountRequestChange, [getBankAccountRequest.isAwaiting])

    const saveBankAccounRequest = useRequest<IBankAccountResponseDTO>()
    useEffect(onSaveBankAccountRequestChange, [saveBankAccounRequest.isAwaiting])

    const deleteBankAccountRequest = useRequest<void>('none')
    useEffect(onDeleteBankAccountRequestChange, [deleteBankAccountRequest.isAwaiting])

    /**
     * Inicializa a tela para criar nova conta bancaria ou obtem dados da conta passada como parametro
     */
    function initialize(): void {

        if (!props.visible)
            return

        // Sempre reseta variaveis locais para nao pegar de forms antigos
        setLoadedBankAccount(undefined)
        formStateManager.reset(new BankAccountFormModel({
            isActive: true,
            integrationConfig: { integrationKey: '' }
        }))

        if (props.bankAccountCode) {
            getBankAccountRequest.runRequest(BankAccountRequests.getOneConfig(props.bankAccountCode))
            return
        }

    }

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetBankAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getBankAccountRequest, 'Erro obter conta bancária para atualizacao'))
            return

        const bankAccountResult = getBankAccountRequest.responseData!
        console.log("A CONTA ", bankAccountResult )
        setAvancado(true);
        setLoadedBankAccount(bankAccountResult)

        setFormValidator(new BankAccountFormModel({
            name: bankAccountResult.name,
            bankCode: bankAccountResult.id,
            initialBalance: MaskUtils.applyMoneyMask(bankAccountResult.initialBalance),
            modality: props.type === BankAccountTypeEnum.BANK_ACCOUNT ? (bankAccountResult.config as IBankAccountConfig).modality : undefined,
            bankAccountTargetCode: bankAccountResult.bankAccountTarget?.code,
            integrationConfig:bankAccountResult.config,
            isActive: bankAccountResult.isActive
        }))

    }

    /**
     * Salva conta bancaria
     */
    async function saveBankAccount(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        // Seta a configuracao correta de acordo com o tipo da conta bancaria
        let config
        if (props.type === BankAccountTypeEnum.PAYMENT_ACCOUNT)
            config = formValues.integrationConfig

        else if (props.type === BankAccountTypeEnum.BANK_ACCOUNT)
            config = formValues.integrationConfig? formValues.integrationConfig : { modality: formValues.modality } as IBankAccountConfig

        config.modality = formValues.modality;

        const dto: IBankAccountSaveRequestDTO = {
            name: formValues.name,
            initialBalance: MoneyUtils.convertToFloat(formValues.initialBalance),
            type: props.type,
            config,
            id: formValues.bankCode,
            bankAccountTargetCode: formValues.bankAccountTargetCode,
            isActive: formValues.isActive
        }

        if (!!props.bankAccountCode)
            saveBankAccounRequest.runRequest(BankAccountRequests.updateConfig(props.bankAccountCode, dto))
        else
            saveBankAccounRequest.runRequest(BankAccountRequests.saveConfig(dto))
    }

    /**
     * Apos reotorno da api de salvar
     */
    function onSaveBankAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveBankAccounRequest, 'Ocorreu algun erro ao salvar conta bancaria'))
            return

        NotificationHelper.info('Yes', 'Conta bancária salva com sucesso')

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    /**
     * Remover conta.
     */
    function deleteBankAccount(): void {

        if (!props.bankAccountCode)
            return

        deleteBankAccountRequest.runRequest(BankAccountRequests.deleteConfig(props.bankAccountCode))
    }

    /**
     *  Retorno da remocao de conta.
     */
    function onDeleteBankAccountRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteBankAccountRequest, 'Não foi remover a conta bancária', true))
            return

        formStateManager.reset(undefined)
        props.onClose(true)
    }

    console.log("FORMSTATE ", formStateManager.getFormValues())
    return (
        <DrawerCP
            title={BankAccountUtils.getBankAccountTypeLabel(props.type)}
            width={props.type === BankAccountTypeEnum.PAYMENT_ACCOUNT ? 600 : 600}
            visible={props.visible}
            loading={getBankAccountRequest.isAwaiting}
            onClose={() => props.onClose(false)}
            footerSpaced={!!props.bankAccountCode}
            footer={
                <>
                    {
                        props.bankAccountCode &&
                        <ButtonCP
                            type={'danger'}
                            loading={deleteBankAccountRequest.isAwaiting}
                            confirmMsg={'Você tem certeza que deseja remover esta conta?'}
                            onClick={deleteBankAccount}
                        >
                            Remover
                        </ButtonCP>
                    }
                    <ButtonCP
                        type={'primary'}
                        onClick={saveBankAccount}
                        loading={saveBankAccounRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </>
            }

        >
            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        label={'Nome'}
                        fontSize={'extraLarge'}
                        fieldName={'name'}
                        formStateManager={formStateManager}
                        required={true}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <ColumnCP size={24}>
                    <FlexCP alignItems={'flex-start'}>
                        <IconBankAccountLogoCP bankLogoUrl={loadedBankAccount?.bankLogoUrl} showBorder={true}/>
                        <SelectBankByTypeCP
                            type={props.type}
                            formStateManager={formStateManager}
                        />
                    </FlexCP>
                </ColumnCP>
            </RowCP>
            {
                props.type === BankAccountTypeEnum.BANK_ACCOUNT &&
                <RowCP>
                    <ColumnCP size={24}>
                        <BankAccountModalitySelectorCP
                            formStateManager={formStateManager}
                        />
                    </ColumnCP>
                </RowCP>
            }
            {
                props.type === BankAccountTypeEnum.PAYMENT_ACCOUNT &&
                <RowCP>
                    <ColumnCP size={24}>
                        <AutocompleteBankAccountCP
                            formStateManager={formStateManager}
                            label={'Conta Bancária destino para repasse'}
                            fieldName={'bankAccountTargetCode'}
                            initialOptions={loadedBankAccount?.bankAccountTarget ? [loadedBankAccount?.bankAccountTarget] : undefined}
                        />
                    </ColumnCP>
                </RowCP>
            }
            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        label={'Saldo Inicial'}
                        formStateManager={formStateManager}
                        fieldName={'initialBalance'}
                        type={'text'}
                        mask={InputMaskTypeEnum.MONEY}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <ColumnCP size={24}>
                    <SwitchCP
                        isTextInside={false}
                        textOutside={'Conta ativa?'}
                        formStateManager={formStateManager}
                        fieldName={'isActive'}
                    />
                </ColumnCP>
            </RowCP>

            {
                (props.type === BankAccountTypeEnum.PAYMENT_ACCOUNT || props.type === BankAccountTypeEnum.BANK_ACCOUNT) &&
                <BasicStyleWrapperCP margin={{ top: 30 }}>
                    <CollapseCP
                        bordered={true}
                        panels={[
                            {
                                key: 'adv',
                                title: 'Configurações Avançadas',
                                content:
                                    <>
                                        <Espaco>
                                            <ButtonCP
                                                type={'primary'}
                                                onClick={()=>{
                                                    setAvancado(false);
                                                    let atual = formStateManager.getFieldValue('integrationConfig');
                                                    let modalidade = formStateManager.getFieldValue('modality');
                                                    let key = atual?atual['integrationKey'] || 'nulo':null;
                                                    let ob:any = UtilGeral.configPadraoDeConta();
                                                    ob['integrationKey'] = key;
                                                    if(modalidade)ob['modality'] = modalidade;
                                                    formStateManager.changeFieldValue('integrationConfig', ob)
                                                    setTimeout(()=>{
                                                        setAvancado(true);
                                                    },1000);
                                                }}
                                                loading={saveBankAccounRequest.isAwaiting}
                                            >
                                                Reiniciar Configurações de Integração
                                            </ButtonCP>
                                        </Espaco>
                                        <ConditionalRenderCP shouldRender={avancado}>

                                            <EditorJsonCP
                                                value={formStateManager.getFieldValue('integrationConfig')}
                                                onChange={(val) =>{
                                                    formStateManager.changeFieldValue('integrationConfig', val)
                                                } }
                                            />
                                        </ConditionalRenderCP>
                                        <HelpCP
                                            marginTop={10}
                                            text={'Altere apenas os valores de cada configuração acima, não altere o nome da propriedade. Se tiver em dúvida contate o suporte. Alteração mal feita pode interromper a integração.'}
                                            type={'text'}
                                        />
                                    </>
                            }
                        ]}
                    />
                </BasicStyleWrapperCP>
            }
        </DrawerCP>
    )
}


const Espaco = styled.div`
    padding-block:15px;
`