import * as _ from 'lodash'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect } from 'react'
import { ButtonToogleCheckinCP } from 'main/modules/scheduler/components/button-toogle-checkin/ButtonToogleCheckinCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCancelStartedAttendanceCP } from 'main/modules/scheduler/components/button-cancel-started-attendance/ButtonCancelStartedAttendanceCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

interface IAppointmentDetailsActionsICPProps {
    sessionCodes: number[]
    sessionStatus: SessionStatusEnum
    appointmentStartDate?: Date
    onActionDone: () => void
}

/**
 * Agrupa as Actions do componente que exibe os detalhes de um agendamento
 */
export function AppointmentDetailsActionsICP(props: IAppointmentDetailsActionsICPProps): JSX.Element {
    const botaoNoShow = !!AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.schedule?.noShowBeforeDate;
    console.log("USer Franchisor ", AppStateUtils.getUserFranchisor());
    const changeAppointmentStatusRequest = useRequest<void>(undefined, 'none')
    useEffect(onChangeAppointmentStatusRequestChange, [changeAppointmentStatusRequest.isAwaiting])

    const deleteAppointmentRequest = useRequest<void>(undefined, 'none')
    useEffect(onDeleteAppointmentRequestChange, [deleteAppointmentRequest.isAwaiting])

    /**
     * Marca como nao compareceu.
     */
    function markAppointmentAsNoShow(): void {

        if (_.isEmpty(props.sessionCodes))
            return

        changeAppointmentStatusRequest.runRequest(SchedulerRequests.markAsNoShow(props.sessionCodes))
    }

    /**
     * Retorno ao marcar como nao compareceu.
     */
    function onChangeAppointmentStatusRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changeAppointmentStatusRequest, 'Não foi possível remover o agendamento', true))
            return

        NotificationHelper.success('Yes!', 'Status alterado com sucesso')
        props.onActionDone()
    }

    /**
     * Remove o agendamento
     */
    function deleteAppointment(): void {

        if (_.isEmpty(props.sessionCodes))
            return

        deleteAppointmentRequest.runRequest(SchedulerRequests.cancelAppointments(props.sessionCodes))
    }

    /**
     * Retorno ao remover agendamento.
     */
    function onDeleteAppointmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(deleteAppointmentRequest, 'Erro ao remover agendamento', true))
            return

        NotificationHelper.success('Pronto!', 'Agendamento removido com sucesso')
        props.onActionDone()
    }

    return (
        <>
            <FlexCP>
                {
                    (props.sessionStatus === SessionStatusEnum.SCHEDULED || props.sessionStatus === SessionStatusEnum.NO_SHOW || props.sessionStatus === SessionStatusEnum.CONCLUDED) &&
                    <ButtonCP
                        type={'danger'}
                        style={{marginRight: '5px'}}
                        onClick={() => deleteAppointment()}
                        loading={deleteAppointmentRequest.isAwaiting}
                        confirmMsg={
                            props.sessionStatus === SessionStatusEnum.CONCLUDED
                                ? 'Essa sessão já foi evoluída, você tem certeza que deseja remover a evolução e cancelar o agendamento?'
                                : 'Você tem certeza que deseja excluir esse agendamento?'
                        }
                    >
                        Cancelar Agendamento
                    </ButtonCP>
                }
                {
                    props.sessionStatus === SessionStatusEnum.SCHEDULED && (moment().isAfter(props.appointmentStartDate) || botaoNoShow) &&
                    <ButtonCP
                        type={'ghost'}
                        onClick={() => markAppointmentAsNoShow()}
                        loading={changeAppointmentStatusRequest.isAwaiting}
                        confirmMsg={'O cliente realmente não compareceu à sessão?'}
                    >
                        Não compareceu
                    </ButtonCP>
                }
            </FlexCP>
            <div>
                <ButtonToogleCheckinCP
                    sessionCodes={props.sessionCodes}
                    sessionStatus={props.sessionStatus}
                    onDone={props.onActionDone}
                />
                <ButtonCancelStartedAttendanceCP
                    sessionCodes={props.sessionCodes}
                    sessionStatus={props.sessionStatus}
                    onDone={props.onActionDone}
                />
            </div>
        </>
    )
}
