import React, { ReactText } from 'react'
import { ColumnProps, FilterDropdownProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { SearchFilterDropdownICP } from 'common/old-refactory/table/inner/SearchFilterDropdownICP'
import { Icon } from 'antd'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import _ from 'lodash'
import { TagTruncateColumnItemCP } from 'main/modules/people/components/tag-truncate-column-item/TagTruncateColumnItemCP'
import { AutoCompleteFilterDropdownICP } from 'common/old-refactory/table/inner/AutoCompleteFilterDropdownICP'
import { TagRequests } from 'main/modules/tags/services/TagRequests'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { IPersonSearchRequestDTO } from 'main/modules/people/services/person/dtos/request/IPersonSearchRequestDTO'
import { TextPersonPhoneCP } from 'main/modules/people/components/text-person-phone/TextPersonPhoneCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { IProfessionalResponseDTO } from 'main/modules/people/services/person/dtos/response/IProfessionalResponseDTO'
import { TextCP } from 'main/common/components/text/TextCP'
import styled from 'styled-components'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { IconCP } from 'main/common/components/icons/IconCP'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'

/**
 * UTILITARIOS do componente de listagem de pessoas.
 */
export const PersonTableUtils = {

    getPersonTableColumnsConfig(
        onSelectPerson: (personCode: number) => void,
        setFilters: React.Dispatch<React.SetStateAction<IPersonSearchRequestDTO>>,
        filters: IPersonSearchRequestDTO,
    ): Array<ColumnProps<IPersonResponseDTO>> {
        return [
            {
                title: 'Nome',
                key: 'name',
                render: (text, person) => person.name,
                filterDropdown: (filterProps: FilterDropdownProps): JSX.Element => (
                    <SearchFilterDropdownICP
                        selectedKeys={filterProps.selectedKeys ?? ''}
                        setSelectedKeys={filterProps.setSelectedKeys}
                        dataIndex={'name'}
                        onReset={(): void => setFilters({ ...filters, page: 1, name: undefined })}
                        onSearch={(selectedKeys?: ReactText[] | string): void => {
                            if (!!selectedKeys)
                                setFilters({ ...filters, page: 1, name: selectedKeys ? selectedKeys as string : '' })
                        }}
                        placeholder={'Nome do contato'}
                    />
                ),
                filterIcon: (): JSX.Element => (<Icon type={'search'}/>),
            },
            {
                title: 'CPF / CNPJ',
                key: 'cpf',
                render: (text, person) => MaskUtils.applyCpfOrCnpjMask(person.cpfCnpj),
            },
            {
                title: 'Telefone',
                key: 'phone',
                render: (text, person) => <TextPersonPhoneCP phone={person.phone}/>,
                filterDropdown: (filterProps: FilterDropdownProps): JSX.Element => (
                    <SearchFilterDropdownICP
                        selectedKeys={filterProps.selectedKeys ?? ''}
                        setSelectedKeys={filterProps.setSelectedKeys}
                        dataIndex={'phone'}
                        onReset={(): void => setFilters({ ...filters, page: 1, phone: undefined })}
                        onSearch={(selectedKeys?: ReactText[] | string): void => {
                            if (!!selectedKeys)
                                setFilters({ ...filters, page: 1, phone: selectedKeys ? `${MaskUtils.removeMask(_.trim(selectedKeys as string)) ?? ''}` : '' })
                        }}
                        placeholder={'Telefone do contato'}
                    />
                ),
                filterIcon: (): JSX.Element => (<Icon type={'search'}/>),
            },
            {
                title: 'E-mail',
                key: 'email',
                render: (text, person) => person.email,
                filterDropdown: (filterProps: FilterDropdownProps): JSX.Element => (
                    <SearchFilterDropdownICP
                        selectedKeys={filterProps.selectedKeys ?? ''}
                        setSelectedKeys={filterProps.setSelectedKeys}
                        dataIndex={'email'}
                        onReset={(): void => setFilters({ ...filters, page: 1, email: undefined })}
                        onSearch={(selectedKeys?: ReactText[] | string): void => {
                            if (!!selectedKeys)
                                setFilters({ ...filters, page: 1, email: selectedKeys ? selectedKeys as string : '' })
                        }}
                        placeholder={'E-mail do contato'}
                    />
                ),
                filterIcon: (): JSX.Element => (<Icon type={'search'}/>),
            },
            {
                title: 'Tags',
                key: 'tags',
                ellipsis: true,
                render: (person): JSX.Element | string => {
                    if (!_.isEmpty(person.tags))
                        return <TagTruncateColumnItemCP tags={person.tags} onClick={(tag): void => setFilters({ ...filters, tag })}/>
                    return ''
                },
                filterDropdown: (
                    <AutoCompleteFilterDropdownICP
                        searchReqConfigGetter={TagRequests.search}
                        onChange={(tag: number): void => setFilters({ ...filters, page: 1, tag: `${tag}` })}
                        placeholder={'Selecione as tags'}
                        onReset={(): void => setFilters({ ...filters, page: 1, tag: undefined })}
                    />
                ),
                filterIcon: (): JSX.Element => (<Icon type={'filter'}/>),
            },
            {
                title: '',
                key: 'actions',
                align: 'right',
                render: (person): JSX.Element => {
                    return (
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                icon={'edit'}
                                type={'primary'}
                                onClick={() => onSelectPerson(person.code)}
                                size={'small'}
                            />
                        </FlexCP>
                    )
                }
            }
        ]
    },

    getUserTableColumns(
        viewDetails: (code: number) => void,
        ativarDesativar: (person: any) => void,
    ): Array<ColumnProps<IProfessionalResponseDTO>> {

        return [
            {
                title: 'Nome',
                key: 'name',
                render: (text, user) => (
                    <>
                        <ConditionalRenderCP shouldRender={!user.active}>
                            <TooltipCP text={'Este usuário se encontra desativado'} placement='top'>
                                <div>
                                    <IconCP antIcon={'arrow-down'} color='red'/>
                                    {' '+user.name}
                                </div>
                            </TooltipCP>
                        </ConditionalRenderCP>
                        <ConditionalRenderCP shouldRender={!!user.active}>
                            {user.name}
                        </ConditionalRenderCP>
                    </>
                    
                )
            },
            {
                title: 'CPF',
                key: 'cpf',
                render: (text, user) => !!user.cpfCnpj ? MaskUtils.applyMask(user.cpfCnpj, InputMaskTypeEnum.CPF) : undefined,
            },
            {
                title: 'E-mail',
                key: 'email',
                render: (collaborator): JSX.Element => (
                    <TextCP text={collaborator.email}/>
                )
            },
            {
                title: 'Telefone',
                key: 'phone',
                render: (text, user) => <TextPersonPhoneCP phone={user.phone}/>,
            },
            {
                key: 'actions',
                align: 'right',
                render: (text, user) => (
                    <Separador>
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                type={'primary'}
                                icon={'edit'}
                                onClick={(): void => viewDetails(user.code)}
                                tooltip={'Clique para editar os dados do usuário'}
                            />
                        </FlexCP>
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                type={'primary'}
                                icon={'exclamation'}
                                onClick={(): void => ativarDesativar(user)}
                                tooltip={'Clique para ativar ou desativar o usuário'}
                            />
                        </FlexCP>
                    </Separador>
                )
            }
           
        ]
    },

}


const Separador = styled.div`
    display:flex;
    gap:3px;
`