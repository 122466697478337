import { faCheckDouble, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Switch } from 'antd'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { TextCP } from 'main/common/components/text/TextCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { HelpTP } from 'main/common/components/help/inner/HelpTP'
import { HelpCP } from 'main/common/components/help/HelpCP'

interface IScpProps {
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
    textUpside?: boolean
}

interface ISwitchCPProps<FModelTP extends FormModel = any> extends IScpProps{

    isTextInside: boolean
    isChecked?: boolean
    onChange?: (checked: boolean) => void

    textOutside?: string
    textOutsidePosition?: 'left' | 'right' | 'top'
    textInsideChecked?: string
    textInsideUnchecked?: string
    disabled?: boolean

    formStateManager?: IFormStateManager<FModelTP>
    fieldName?: keyof FModelTP
    vermelho?:string
    hint?: HelpTP
}

/**
 * COMPONENTE
 * Elemento de selecao unica do tipo 'Switch'.
 */
export function SwitchCP<FModelTP extends FormModel = any>(props: ISwitchCPProps<FModelTP>): JSX.Element {

    const shouldShowText: boolean = !props.isTextInside && !!props.textOutside

    const [isChecked, setIsChecked] = useState<boolean>(false)
    useEffect(init, [props.isChecked, props.formStateManager])

    /**
     * Inicializa.
     */
    function init(): void {

        if (props.isChecked)
            return setIsChecked(props.isChecked)

        if (props.formStateManager && props.fieldName)
            return setIsChecked(props.formStateManager.getFieldValue(props.fieldName))

    }

    /**
     * Ao alterar o valor do campo.
     */
    function onChange(checked: boolean): void {

        setIsChecked(checked)
        if (props.formStateManager && props.fieldName)
            props.formStateManager.changeFieldValue(props.fieldName, checked)

        if (props.onChange)
            props.onChange(checked)
    }

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 8}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 8}
            marginLeft={props.marginLeft ?? 0}
        >
            <SwitchWrapperSCP
                textUpside={props.textOutsidePosition === 'top'}
            >
                {
                    (shouldShowText && (props.textOutsidePosition === 'left' || props.textOutsidePosition === 'top')) &&
                    <TextCP text={props.textOutside} marginRight={5} marginBottom={props.textOutsidePosition === 'top' ? 5 : 0}/>
                }

                <Switch
                    checkedChildren={props.isTextInside ? props.textInsideChecked : <FontAwsomeIconCP type={faCheckDouble}/>}
                    unCheckedChildren={props.isTextInside ? props.textInsideUnchecked : <FontAwsomeIconCP type={faTimesCircle}/>}
                    checked={isChecked}
                    disabled={props.disabled}
                    onChange={onChange}
                    defaultChecked={true}
                    style={props?.vermelho=='sim'?{backgroundColor:'red'}:{}}
                />

                {
                    (shouldShowText && (!props.textOutsidePosition || props.textOutsidePosition === 'right')) &&
                    <TextCP text={props.textOutside} marginLeft={5}/>
                }
            </SwitchWrapperSCP>

            {
                props.hint &&
                <HelpCP
                    marginTop={5}
                    text={props.hint.text}
                    type={props.hint.type}
                />
            }
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div<IScpProps>`
   margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`

const SwitchWrapperSCP = styled.div<IScpProps>`

   display: ${props => !props.textUpside ? 'flex' : undefined};
   align-items: center;
   justify-content: flex-start;

    .ant-switch {
        &.ant-switch-checked {
            background-color: ${(props: StyledComponentProps) => props.theme.primaryColor};
        }
    }

`
