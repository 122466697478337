import { AddressInfoICP } from 'main/modules/people/components/person-main-info-bar/inner/AddressInfoICP'
import { BasicInfoICP } from 'main/modules/people/components/person-main-info-bar/inner/BasicInfoICP'
import { PersonalInfoICP } from 'main/modules/people/components/person-main-info-bar/inner/PersonalInfoICP'
import { UserTreatmentGroupsICP } from 'main/modules/people/components/person-main-info-bar/inner/UserTreatmentGroupsICP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import React, { useState } from 'react'
import styled from 'styled-components'
import { TitleCP } from 'main/common/components/title/TitleCP'
import { DrawerPersonDataCP } from 'main/modules/people/components/drawer-person-data/DrawerPersonDataCP'
import { NotesCP } from 'main/modules/app/components/notes/NotesCP'
import { Restantes } from '../sessoes-restantes/Restantes'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { UtilGeral } from '../../utils/UtilGeral'

interface IPersonMainInfoBarCPProps {
    person: IPersonResponseDTO
    segmentType: SegmentTypeEnum
    onUpdate: (person: IPersonResponseDTO) => void
}

/**
 * Componente wrapper para detalhes de pessoa com form de edição
 */
export function PersonMainInfoBarCP(props: IPersonMainInfoBarCPProps): JSX.Element {

    const [isPersonDrawerVisible, setPersonDrawerVisible] = useState<boolean>(false)

    return (
        <>
            <WrapperSCP>
                <BasicInfoICP
                    name={props.person.name}
                    socialName={props.person.socialName}
                    fantasyName={props.person.fantasyName}
                    cpfCnpj={props.person.cpfCnpj}
                    email={props.person.email}
                    phone={props.person.phone}
                    editAction={() => {
                        const naoPode = AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_HORARIO_TRABALHO) && !AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN);
                        if(naoPode)return;
                        setPersonDrawerVisible(true)
                    }}
                />

                {
                    (props.segmentType === SegmentTypeEnum.COLLABORATOR) &&
                    <UserTreatmentGroupsICP userCode={props.person.code}/>
                }

                <Restantes codigoPessoa={props.person.code}></Restantes>

                <TitleCP textSize={'normal'} underLine={true}>
                    Dados Pessoais
                </TitleCP>

                

                <PersonalInfoICP
                    rg={props.person.rg}
                    birthDate={props.person.birthDate && UtilGeral.formatarDataISO(props.person.birthDate)}
                    gender={props.person.gender}
                    altura={props.person.altura}
                />

                {
                    props.person.address &&
                    <>
                        <TitleCP textSize={'normal'} underLine={true}>
                            Endereço
                        </TitleCP>
                        <AddressInfoICP address={props.person.address}/>
                    </>
                }

                <NotesCP
                    title={<TitleCP textSize={'normal'} underLine={true} marginTop={20}>Notas</TitleCP>}
                    notes={props.person.note}
                />

                    


            </WrapperSCP>

            <DrawerPersonDataCP
                visible={isPersonDrawerVisible}
                onClose={() => setPersonDrawerVisible(false)}
                onSave={(personCreated) => {
                    setPersonDrawerVisible(false)
                    props.onUpdate(personCreated)
                }}
                segmentType={props.segmentType}
                personCode={props.person.code}
            />
        </>
    )
}

const WrapperSCP = styled.div``
