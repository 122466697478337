import { NotificationHelper } from "main/common/helpers/NotificationHelper";

export class UtilGeral {
    static VALIDAR_OBJETO(ob:any, campos:any,nomeAmigavel:any = null){
        for (let i = 0; i<campos.length; i++){
          const cp = campos[i];
          if(!ob[cp] && ob[cp]!=0){
            let amig = null;
            if(nomeAmigavel && nomeAmigavel[i])amig = nomeAmigavel[i];
            const nome = amig || cp;
            NotificationHelper.error('Atenção',`FAVOR ${UtilGeral.TEXTO(nome)}`);
            return false;
          }
        }
        return true;
      }
    
      static TEXTO = (v:any)=>{
        if(!v || v=='null')return '';
        return UtilGeral.REMOVER_CORINGA(UtilGeral.removerAcentos((v+'').toUpperCase()).trim(),[';',':','\\','n']);
      }

      static REMOVER_CORINGA = (v:any,vet:any)=>{
        return (v+'').replace(/\n/g,'').split('').filter(i=>!vet.includes[i]).join('');
      }

      static DEVOLVER_SO_NUMEROS(s:any){
        return (s+'').split('').filter((i:any)=>'0123456789'.includes(i)).join('');
      }



    static objetoVirgulaPorPonto(obj:any) {
      const newObj = {};
  
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              let value = obj[key];
  
              // Verifica se o valor é uma string e representa um float
              if (typeof value === 'string' && !isNaN(parseFloat(value.replace(/,/g, '.'))) && value.includes(',')) {
                  // Substitui a vírgula por ponto
                  value = value.replace(/,/g, '.');
  
                  // Converte a string em número
                  const floatValue = parseFloat(value);
                  if (!isNaN(floatValue)) {
                      value = floatValue;
                  }
              } else if (typeof value === 'object' && value !== null) {
                  // Verifica recursivamente em caso de objeto aninhado
                  value = UtilGeral.objetoVirgulaPorPonto(value);
              }
  
              newObj[key] = value;
          }
      }
  
      return newObj;
  }
 
  

    static objetoPontoPorVirgula(obj:any) {
      const newObj = {};
  
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              let value = obj[key];
  
              // Verifica se o valor é um número float ou uma string que representa um float
              if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)) && value.includes('.') && !value.includes(':') && !value.includes('-') && !value.includes('0Z'))) {
                  // Substitui o ponto por vírgula
                  if (typeof value === 'string') {
                      value = value.replace(/\./g, ',');
                  } else if (typeof value === 'number') {
                      value = value.toString().replace(/\./g, ',');
                  }
              } else if (typeof value === 'object' && value !== null) {
                  // Verifica recursivamente em caso de objeto aninhado
                  value = UtilGeral.objetoPontoPorVirgula(value);
              }
  
              newObj[key] = value;
          }
      }
  
      return newObj;
  }
  

    static configPadraoDeConta(){
      return {"integrationKey": "null", "rulesByPaymentMethods": [{"paymentMethod": "BANK_BILLET", "maxDaysToExpire": 10, "minDaysToExpire": 0, "daysUntilPayment": 5, "enableInstallments": true}, {"paymentMethod": "CREDIT_CARD_RECURRENT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 30, "enableInstallments": true}, {"paymentMethod": "CREDIT_CARD", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 30, "enableInstallments": true}, {"paymentMethod": "PIX", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "MONEY", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "PAYMENT_BOOK", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": true}, {"paymentMethod": "DEPOSIT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "CHECK", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "DEBIT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}]}
    }

  

    static  dateParaStringFormatada(data:any) {
      console.log("Formatando data ", data);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    }

    static formatarDataISO(dataISO:any) {
      const data = new Date(dataISO);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    }






      static removerAcentos(s:any) {
        if (!s) return '';
        return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      }

      static CLONE(a:any){
        return JSON.parse(JSON.stringify(a));
      }

      static DATA_SQL_DE_DATA_FORMATADA(d:any){
        if((d+'').length==8){
            return `${d[4]+d[5]+d[6]+d[7]}-${d[2]+d[3]}-${d[0]+d[1]}`;
        }
        if((d+'').includes('/')){
            const ped = (d+'').split('/');
            return `${ped[2]}-${ped[1]}-${ped[0]}`;
        }
        return d;
      }

}