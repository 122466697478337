import React, { useEffect, useState } from 'react'
import { FranchiseFormModel } from 'main/modules/admin/components/franchise-data-screen-content/inner/FranchiseFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { AddressPanelCP } from 'main/modules/people/components/address-panel/AddressPanelCP'
import styled from 'styled-components'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { FranchiseFormUtils } from 'main/modules/admin/components/franchise-data-screen-content/inner/FranchiseFormUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { IFranchiseSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { Checkbox, Icon, TimePicker } from "antd";
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { HelpCP } from 'main/common/components/help/HelpCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import moment from 'moment'

interface IFranchiseDataScreenContentCPProps {
    franchise: IFranchiseResponseDTO
    onUpdateFranchise: (_franchise: IFranchiseSaveRequestDTO) => void
    loading: boolean
}

/**
 * Dados cadastrais da franquia
 */
export function FranchiseDataScreenContentCP(props: IFranchiseDataScreenContentCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<FranchiseFormModel>()
    const formStateManager = useFormStateManager<FranchiseFormModel>(formValidator)
    const [mensagem, setMensagem] = useState<any>('');
    const [dataInicial,setDataInicial] = useState<any>(null);
    const [carregando,setCarregando] = useState<any>(false);
    const [parametros, setParametros] = useState<any>({});
    useEffect(init, [])

    async function carregarParametros(){
        console.log("Carregando tela");
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/franchises/carregar-parametros`,
            method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>{setCarregando(false)});
        console.log("Resultado ", resultado);
        const ok = !!resultado?.data?.data?.parametros;
        if(ok){
            const para = resultado.data.data.parametros;
            setParametros(para);
            setMensagem(parametros?.businessRulesConfig?.sale?.mensagemNFE);
            console.log("PArAMETROS SETADOS ", para);
            let v = para?.businessRulesConfig?.schedule?.horaInicialAgenda ?? undefined;
            setDataInicial(v);
        }
        setCarregando(false);
    }

    async function atualizarParametros(valor:any,campo:any){
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/franchises/salvar-preferencias`,
            method: HttpMethodEnum.POST,
            params: {
                campo:campo,
                valor:valor,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>{setCarregando(false)});
        setCarregando(false);
        console.log("O resultado ", resultado); 
        setParametros(resultado.data.data.parametros);
        NotificationHelper.success('Parâmetros de unidade atualizados');
    }

    /**
     * Inicializa a tela.
     */
    function init(): void {
        setFormValidator(new FranchiseFormModel(FranchiseFormUtils.getFormData(props.franchise)))
        carregarParametros();
    }

    let timer:any = null;
    function gardarTexto(texto:any){
        clearTimeout(timer);
        timer = setTimeout(()=>{
            setMensagem(texto);
        },490);
    }

    function salvarHorario(){
        const valor = dataInicial ?? '';
        atualizarParametros(valor,'horaInicialAgenda');
    }

    function salvarMensagem(){
        console.log("mensagem ", mensagem);
        atualizarParametros(mensagem,'mensagemNFE');
    }

    /**
     * Ao clicar em salvar.
     */
    async function save(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()

        if (!await formStateManager.validate() || !formValues)
            return

        props.onUpdateFranchise(FranchiseFormUtils.getDto(formValues))
    }

    return (
        <>
            <CardCP title={'Dados Básicos'}>
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'cnpj'}
                            formStateManager={formStateManager}
                            mask={InputMaskTypeEnum.CNPJ}
                            label={'CNPJ'}
                            required={true}
                            disabled={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'stateRegistration'}
                            formStateManager={formStateManager}
                            label={'Inscrição municipal'}
                        />
                    </ColumnCP>
                </RowCP>
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'socialName'}
                            formStateManager={formStateManager}
                            label={'Razão Social '}
                            required={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'fantasyName'}
                            formStateManager={formStateManager}
                            label={'Nome Fantasia'}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'email'}
                            formStateManager={formStateManager}
                            label={'E-mail'}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'phone'}
                            formStateManager={formStateManager}
                            label={'Telefone'}
                            mask={InputMaskTypeEnum.PHONE}
                        />
                    </ColumnCP>
                </RowCP>
            </CardCP>
            <CardCP title={'Endereço'}>
                <AddressPanelCP formStateManager={formStateManager}/>
            </CardCP>

            <CardCP title={'Parâmetros de Unidade'} isLoading={carregando}>
                <Item>
                            <HelpCP
                                marginTop={5}
                                text={'Caso marcado, o detalhamento dos procedimentos é escondido na emissão da NFE'}
                                type={'tooltip'}
                            />
                   
                        <Checkbox onChange={(e:any)=>{
                            atualizarParametros(e.target.checked,'ocultarProcedimentos')
                        }} checked={!!parametros?.businessRulesConfig?.sale?.ocultarProcedimentosNFE}  >
                            Ocultar nome dos procedimentos na NFE.
                        </Checkbox>
                    
                </Item>
                    
                    <Item>
                            <HelpCP
                                marginTop={5}
                                text={'Descrição da NFE emitida (caso informado, na descrição será exibida apenas a mensagem abaixo)'}
                                type={'tooltip'}
                            />
                            <div className="separado">
                                <InputCP
                            
                                    onChange={gardarTexto}
                                    value={parametros?.businessRulesConfig?.sale?.mensagemNFE}
                                    label={'Descrição NFE Personalizada'}
                                />
                                <ButtonCP bgColor='blue' onClick={salvarMensagem} >Salvar Mensagem</ButtonCP>
                            </div>
                    </Item>

                    <Item>
                        <div>
                            Defina a hora inicial mínima da agenda para quando o expediente iniciar antes do horário padrão (08:00h).
                        </div>
                        <HelpCP
                                marginTop={5}
                                text={'A agenda, por padrão começa as 08:00h para a visão de semana, para que seja possível definir um horário mais cedo que o padrão, é necessário parametrizar por aqui.'}
                                type={'tooltip'}
                            />
                        <div className="separado">

                            <TimePicker
                            
                                value={dataInicial?moment(dataInicial, 'HH:mm'):undefined}
                                defaultOpenValue={dataInicial?moment(dataInicial, 'HH:mm'):moment('08:00', 'HH:mm')}
                                minuteStep={5}
                                inputReadOnly={true}
                                onChange={(v:any)=>{
                                    if(!v){
                                        setDataInicial(undefined);
                                        return;
                                    }
                                    setDataInicial(v.format('HH:mm'));
                                    console.log("Setando ", v.format('HH:mm'));
                                }}
                                format={'HH:mm'}
                                size={'small'}
                                suffixIcon={<Icon type={'clock-circle'}/>}
                                placeholder={'Hora'}
                                allowClear={true}
                            />
                            <ButtonCP bgColor='blue' onClick={salvarHorario} >Salvar Horario</ButtonCP>
                        </div>
                    </Item>
                    
            </CardCP>

            <BtnContainerSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={save}
                    loading={props.loading}
                >
                    Salvar
                </ButtonCP>
            </BtnContainerSCP>
        </>
    )
}

const BtnContainerSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 40px 20px;
`

const Item = styled.div`
    border-bottom:dashed 1px gray;
    padding:5px;
    margin-bottom:4px;

    .separado{
        display:flex;
        gap:5px;
        align-items:flex-end;
        justify-content:space-between;
    }
`
